/**
 * Otzy API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyPayments {
    INVALID_IBAN = 'payment-invalid-iban',
    INVALID_SESSION = 'payment-invalid-session',
    INVALID_SENDER = 'payment-invalid-sender',
    INVALID_RECEIVER = 'payment-invalid-receiver',
    INVALID_ECONOMY = 'payment-invalid-economy',
    INVALID_STATUS = 'payment-invalid-status',
    REQUEST_NOT_FOUND = 'payment-request-not-found',
    CONTENT_NOT_FOUND = 'payment-content-not-found'
}

