import { getBrowserSupportedLanguage } from '@icp/angular/i18n';
import { SupportedLanguage } from '@icp/interfaces';
import { createAction, createFeature, createReducer, on, props } from '@ngrx/store';

interface State {
    language: SupportedLanguage;
}

const authState: State = {
    language: getBrowserSupportedLanguage(),
};

export const setLanguage = createAction('[Auth] Set language', props<{ language: SupportedLanguage }>());

export const rootFeature = createFeature({
    name: 'auth',
    reducer: createReducer(
        authState,
        on(setLanguage, (state, { language }): State => ({ ...state, language })),
    ),
});
