export * from './openapi/api/api';
export * from './openapi/model/models';
export * from './openapi/variables';

export enum HttpHeaderNames {
    AUTHORIZATION = 'Authorization',
    APP_SESSION = 'App-Session',
    X_API_KEY = 'x-api-key',
    ACCEPT_LANGUAGE = 'Accept-Language',
}

export enum MultiPartBodyKeysDto {
    JSON_DATA = 'jsonData',
    LOGO = 'logo',
    ICON = 'icon',
    FILE = 'file', // todo unused
    BANNER = 'banner',
    BG_IMAGES = 'bgImages',
}
