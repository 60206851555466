import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { HttpHeaderNames, SupportedLanguage } from '@icp/interfaces';
import { MemoizedSelector, Store } from '@ngrx/store';
import { first, switchMap } from 'rxjs';

type LanguageSelector = MemoizedSelector<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<string, any>,
    SupportedLanguage,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (featureState: any) => SupportedLanguage
>;

export const SetAcceptLanguageHeaderInterceptor =
    (languageSelector: LanguageSelector): HttpInterceptorFn =>
    (req, next) => {
        const store = inject(Store);
        return store.select(languageSelector).pipe(
            first(),
            switchMap((language) =>
                next(
                    req.clone({
                        setHeaders: {
                            // Modify the standard accept-language header, so it places the user his selected language first.
                            // Example result: en,en-GB;q=0.9,nl;q=0.8
                            [HttpHeaderNames.ACCEPT_LANGUAGE]: [language, ...navigator.languages]
                                .map((lang, index) => {
                                    if (index === 0) {
                                        return lang;
                                    }
                                    return `${lang};q=${1 - index * 0.1}`;
                                })
                                .join(','),
                        },
                    }),
                ),
            ),
        );
    };
