import { DOCUMENT } from '@angular/common';
import {
    inject,
    Injectable,
    InjectionToken,
    isDevMode,
    LOCALE_ID,
    makeEnvironmentProviders,
    Type,
} from '@angular/core';
import { SupportedLanguage } from '@icp/interfaces';
import { provideTransloco, provideTranslocoLoader, TranslocoLoader, TranslocoService } from '@jsverse/transloco';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { MemoizedSelector, Store } from '@ngrx/store';
import type { Locale } from 'date-fns';
import { distinctUntilChanged, firstValueFrom, switchMap } from 'rxjs';

import {
    DEFAULT_LANGUAGE,
    getBrowserSupportedLanguage,
    getLocale,
    SUPPORTED_LANGUAGES,
} from './get-supported-language';

const LANGUAGE_SERVICE_LANGUAGE_SUBJECT = new InjectionToken<
    MemoizedSelector<Record<string, unknown>, SupportedLanguage>
>('LanguageServiceFeature');
const initialLanguage = getBrowserSupportedLanguage();

export function provideLanguageService(config: {
    selector: MemoizedSelector<Record<string, unknown>, SupportedLanguage>;
    loader: Type<TranslocoLoader>;
}) {
    return makeEnvironmentProviders([
        { provide: LANGUAGE_SERVICE_LANGUAGE_SUBJECT, useValue: config.selector },
        // Try to avoid using LOCALE_ID whenever possible as it cannot be updated at runtime,
        // use TranslocoLocaleService#getLocale instead.
        // We're still setting it to ensure matDatepicker shows the correct month names
        { provide: LOCALE_ID, useValue: getLocale(initialLanguage) },
        provideTransloco({
            config: {
                availableLangs: SUPPORTED_LANGUAGES,
                defaultLang: initialLanguage,
                fallbackLang: DEFAULT_LANGUAGE,
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            },
        }),
        provideTranslocoLoader(config.loader),
        provideTranslocoLocale({
            langToLocaleMapping: {
                [SupportedLanguage.EN]: 'en-US',
                [SupportedLanguage.NL]: 'nl-BE',
                [SupportedLanguage.FR]: 'fr-FR',
            },
        }),
    ]);
}

@Injectable({ providedIn: 'root' })
export class LanguageService {
    supportedLanguages: { name: string; code: SupportedLanguage }[] = [
        { name: 'Nederlands', code: SupportedLanguage.NL },
        { name: 'Français', code: SupportedLanguage.FR },
        { name: 'English', code: SupportedLanguage.EN },
    ];
    language: SupportedLanguage = initialLanguage;
    private readonly transloco = inject(TranslocoService);
    private readonly store = inject(Store);
    private readonly languageSelector = inject(LANGUAGE_SERVICE_LANGUAGE_SUBJECT);
    private readonly document = inject(DOCUMENT);

    /**
     * Must be called from the APP_INITIALIZER function to ensure the correct language is set and loaded.
     */
    initialize() {
        const languageObservable = this.store.select(this.languageSelector).pipe(distinctUntilChanged());
        languageObservable.subscribe((language) => {
            this.transloco.setActiveLang(language);
            this.document.documentElement.lang = language;
            this.language = language;
        });
        return firstValueFrom(languageObservable.pipe(switchMap((lang) => this.transloco.load(lang))));
    }

    async getDateFnsLocale(): Promise<Locale> {
        switch (this.language) {
            case SupportedLanguage.NL:
                return await import('date-fns/locale/nl-BE').then((module) => module.nlBE);
            case SupportedLanguage.EN:
                return await import('date-fns/locale/en-GB').then((module) => module.enGB);
            case SupportedLanguage.FR:
                return await import('date-fns/locale/fr').then((module) => module.fr);
        }
    }
}
