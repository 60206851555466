/**
 * Otzy API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyStore {
    NOT_FOUND = 'store-not-found',
    ALREADY_REGISTERED_IN_ECONOMY = 'store-already-registered-in-economy',
    NOT_REGISTERED_IN_ECONOMY = 'store-not-registered-in-economy',
    NO_PERMISSION = 'store-no-permission'
}

