export type ThemeConfig = {
    [key in ThemeMainColorType]?: string;
};

export enum ThemeMainColorType {
    PRIMARY = 'primary',
    PRIMARY_CONTRAST = 'primaryContrast',
    ACCENT = 'accent',
    ACCENT_CONTRAST = 'accentContrast',
}
