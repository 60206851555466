import { SupportedLanguage } from '@icp/interfaces';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';

// en-GB first to avoid US formatting in non US countries
export const SUPPORTED_LOCALES = ['en-GB', 'en-US', 'nl', 'fr'] as const;
export type SupportedLocale = (typeof SUPPORTED_LOCALES)[number];
export const DEFAULT_LOCALE: SupportedLocale = 'nl';
// Must match filenames of the files in assets/i18n for *all* embedded apps
export const SUPPORTED_LANGUAGES = Object.values(SupportedLanguage);
export const DEFAULT_LANGUAGE = SupportedLanguage.NL;

export function getBrowserSupportedLanguage() {
    for (const lang of navigator.languages) {
        const supportedLanguage = getSupportedLanguage(lang);
        if (supportedLanguage) {
            return supportedLanguage;
        }
    }
    return DEFAULT_LANGUAGE;
}

export function getSupportedLanguage(
    language: string,
    supportedLanguages = SUPPORTED_LANGUAGES,
): SupportedLanguage | null {
    language = language.replace('_', '-');
    const split = language.split('-');
    for (const lang of supportedLanguages) {
        if (lang.startsWith(language) || split[0] === lang) {
            return lang;
        }
    }
    return null;
}

export function getLocale(lang: string): SupportedLocale {
    const userLocale = lang.replace('_', '-');
    if (SUPPORTED_LOCALES.includes(userLocale as SupportedLocale)) {
        return userLocale as SupportedLocale;
    }
    const language = userLocale.split('-')[0];
    for (const locale of SUPPORTED_LOCALES) {
        if (locale.startsWith(language)) {
            return locale;
        }
    }
    return DEFAULT_LOCALE;
}

export function getLocaleMonthNames(
    localeService: TranslocoLocaleService,
    format: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' = 'long',
) {
    const locale = localeService.getLocale();
    return [...Array(12).keys()].map((m) =>
        new Intl.DateTimeFormat(locale, { month: format }).format(new Date(2021, m)),
    );
}

export function getLocaleDayNames(localeService: TranslocoLocaleService, format: 'long' | 'short' | 'narrow' = 'long') {
    const locale = localeService.getLocale();
    const weekdayDateMap = {
        Sun: new Date('2020-01-05T00:00:00.000Z'),
        Mon: new Date('2020-01-06T00:00:00.000Z'),
        Tue: new Date('2020-01-07T00:00:00.000Z'),
        Wed: new Date('2020-01-08T00:00:00.000Z'),
        Thu: new Date('2020-01-09T00:00:00.000Z'),
        Fri: new Date('2020-01-10T00:00:00.000Z'),
        Sat: new Date('2020-01-11T00:00:00.000Z'),
    } as const;
    const shortWeekdays = Object.keys(weekdayDateMap) as (keyof typeof weekdayDateMap)[];
    return shortWeekdays.map((shortName) =>
        new Intl.DateTimeFormat(locale, { weekday: format }).format(weekdayDateMap[shortName]),
    );
}
