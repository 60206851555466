/**
 * Otzy API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyEmails {
    ALREADY_IN_USE = 'email-already-in-use',
    NOT_FOUND = 'email-not-found',
    ALREADY_INVITED = 'email-already-invited',
    ALREADY_A_MEMBER = 'email-already-a-member'
}

