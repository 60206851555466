/**
 * Otzy API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StoreWhitelistedNextAction } from './store-whitelisted-next-action';


export interface StoreWhitelistedEconomyPushDataDto { 
    action: StoreWhitelistedEconomyPushDataDtoAction;
    nextAction: StoreWhitelistedNextAction;
    storeId: string;
    businessId: string;
    economyId: string;
}
export enum StoreWhitelistedEconomyPushDataDtoAction {
    STORE_WHITELISTED_ECONOMY = 'storeWhitelistedEconomy'
};



