/**
 * Otzy API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum WebhookEvent {
    STORE_CREATE = 'store/create',
    STORE_UPDATE = 'store/update',
    STORE_DELETE = 'store/delete',
    STORE_TRANSACTIONS = 'store/transactions',
    SPENDER_TRANSACTIONS = 'spender/transactions',
    FUND_TRANSACTIONS = 'fund/transactions',
    PAYMENT_LOOKUP = 'payment/lookup'
}

