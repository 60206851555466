/**
 * Otzy API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum RoleName {
    ECONOMY_ADMIN = 'ECONOMY_ADMIN',
    SPENDER_ADMIN = 'SPENDER_ADMIN',
    SPENDER = 'SPENDER',
    BUSINESS_ADMIN = 'BUSINESS_ADMIN',
    STORE_ADMIN = 'STORE_ADMIN',
    STORE_EMPLOYEE = 'STORE_EMPLOYEE',
    VERTICAL_ECONOMY_ADMIN = 'VERTICAL_ECONOMY_ADMIN'
}

