/**
 * Otzy API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyAuthorization {
    MISSING = 'authorization-missing',
    INVALID = 'authorization-invalid',
    EXPIRED = 'authorization-expired',
    SESSION_MISSING = 'authorization-session-missing',
    SESSION_INVALID = 'authorization-session-invalid',
    STORE_MISSING = 'authorization-store-missing',
    STORE_INVALID = 'authorization-store-invalid'
}

