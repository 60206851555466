import { HttpErrorResponse } from '@angular/common/http';
import { delay, Observable, of, retry, throwError } from 'rxjs';

const DEFAULT_MAX_RETRIES = 5;
const DEFAULT_BACKOFF = 1000;

export function retryWithBackoff<T>(maxRetry = DEFAULT_MAX_RETRIES, backoffMs = DEFAULT_BACKOFF) {
    let retries = 0;
    return (src: Observable<T>) =>
        src.pipe(
            retry({
                delay: (error) => {
                    // No retries for http errors 400-500 as those should always return the same status
                    if (error instanceof HttpErrorResponse && error.status >= 400 && error.status < 500) {
                        return throwError(() => error);
                    }
                    if (retries++ < maxRetry) {
                        const backoffTime = backoffMs * Math.pow(2, retries);
                        console.debug(`Retrying ${retries} in ${backoffTime}ms`);
                        return of(error).pipe(delay(backoffTime));
                    }
                    return throwError(() => error);
                },
            }),
        );
}
