/**
 * Otzy API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ErrorKeyVertical {
    NOT_FOUND = 'vertical-not-found',
    STILL_HAS_API_KEYS = 'vertical-still-has-api-keys',
    STILL_HAS_WEBHOOKS = 'vertical-still-has-webhooks'
}

